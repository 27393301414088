<template>
  <div class="page-wrapper manufacture">
    <div class="jumbotron">
      <div class="container">
        <h2 class="mb-4 heading" data-aos="fade-in">Infrastructure</h2>
      </div>
    </div>
    <v-container>
      <v-row style="margin-top: 5%">
        <v-col col-md-4>
          <h3><span>CNC MACHINES</span></h3>
        </v-col>
        <v-col>
          <img
            class="image1"
            src="../assets/img/cnc_table.jpeg"
            alt="Machine Images"
          />
        </v-col>
      </v-row>
      <v-simple-table style="margin-top: 4%">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Machine Type</th>
              <th class="text-left">Make</th>
              <th class="text-left">Model</th>
              <th class="text-left">Specification</th>
              <th class="text-left">Accuracy</th>
            </tr>
          </thead>
          <tbody :key="index" v-for="(machine, index) in Machines">
            <tr v-if="machine.table == 'Table1'">
              <td>{{ machine.type }}</td>
              <td>{{ machine.made }}</td>
              <td>{{ machine.model }}</td>
              <td>{{ machine.specification }}</td>
              <td>{{ machine.accuracy }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-row style="margin-top: 5%">
        <v-col col-md-4>
          <h3><span>CONVENTIONAL MACHINES</span></h3>
        </v-col>
        <v-col>
          <img
            class="image2"
            src="../assets/img/Conventional.jpg"
            alt="Machine Images"
          />
        </v-col>
      </v-row>
      <v-simple-table style="margin-top: 4%">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Machine Type</th>
              <th class="text-left">Make</th>
              <th class="text-left">Model</th>
              <th class="text-left">Specification</th>
              <th class="text-left">Accuracy</th>
            </tr>
          </thead>
          <tbody :key="index" v-for="(machine, index) in Machines">
            <tr v-if="machine.table == 'Table2'">
              <td>{{ machine.type }}</td>
              <td>{{ machine.made }}</td>
              <td>{{ machine.model }}</td>
              <td>{{ machine.specification }}</td>
              <td>{{ machine.accuracy }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
    <div class="container solidwork">
      <v-row style="margin-top: 5%">
        <v-col>
          <img
            class="image3"
            data-aos="fadeRight"
            src="../assets/img/solidworks.jpeg"
            alt="Scope of Field"
            width="100%"
            height="100%"
          />
        </v-col>
        <v-col>
          <h3>
            <span
              >Authorized SOLIDWORKS 2020 Perpetual License Holder, With Skilled
              Design Engineers</span
            >
          </h3>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { db } from "../firebase.js";
export default {
  name: "infrastructure",
  data() {
    return {
      Machines: [],
    };
  },
  created() {
    db.collection("Machines")
      .orderBy("type", "asc")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          let machine = doc.data();
          machine.id = doc.id;
          this.Machines.push(machine);
        });
      });
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Infrastructure",
    // all titles will be injected into this template
    titleTemplate: "ManoTools - %s",
  },
};
</script>

<style lang="css" scoped>
.image1 {
  max-width: 45%;
  position: relative;
  margin-left: 45%;
  margin-top: -4%;
  height: auto;
}

.image2 {
  max-width: 85%;
  position: relative;
  margin-left: 35%;
  margin-top: -4%;
  height: auto;
}

.image3 {
  max-width: 85%;
  position: relative;
  margin-top: -6%;
  height: auto;
}

tr {
  border: thin solid rgba(0, 0, 0, 0.12);
}
td {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
th {
  border-right: thin solid rgba(0, 0, 0, 0.12);
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
}
h3 {
  color: #050674;
  text-align: center;
  font-size: 24px;
  margin-top: 20%;
  margin-bottom: 30px;
  text-align: justify;
}
h3 span {
  font-weight: 900 !important;
}
.jumbotron {
  background: linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.4),
        rgba(5, 6, 116, 0.6)
      )
      fixed,
    url("../assets/img/manufacture.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  border-radius: 0px !important;
}
.heading {
  text-align: center;
  font-weight: 700;
  color: #ffffff;
}

@media (min-width: 0px) and (max-width: 450px) {
  .image3 {
    margin-top: 50%;
  }
}
</style>
