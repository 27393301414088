<template>
  <div class="page-wrapper about">
    <div class="jumbotron">
      <div class="container">
        <h2 class="mb-4 heading fw-7" data-aos="fade-in">
          About <span class="fw-6">Mano Tools</span>
        </h2>
      </div>
    </div>
    <div class="founderbg">
      <div class="container-fluid">
        <div class="founder row">
          <div class="col-12 col-lg-6 col-xl-6 col-sm-12 col-md-6">
            <div class="card mx-auto" data-aos="fade-right">
              <div class="imgBx">
                <img
                  src="../assets/img/founder.jpg"
                  alt="Founder's Image"
                  class="founderimage"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 col-xl-6 col-sm-12 col-md-6">
            <div class="quotes" data-aos="fade-left">
              <p>
                <strong>Mr. K. Manohar </strong> Founder & CEO of Mano Tools, He
                has over Thirty plus years of experience in a broad range of
                engineering roles in various industries.<br /><br />
                Prior to the Challenge of Creating an Organization, He has
                played various roles as Machine Operator, Supervisor, Shop Floor
                In-charge Etc., He has wide variety of exposure in manufacturing
                industry, especially on Jigs & Fixtures, Cutting Tools, Press
                Tools, Castings & SPM. Based on his strong leadership and team
                spirit, Mano Tools has become a Tool Room Company from Small
                Machine Shop which worked for Labour jobs. His vision is to
                setup a Complete Tool Room Industry with Heavy Fabrications and
                also a Dedicated Unit for Production activities which supports
                the society in terms of Engineering, Science & Technology.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div id="container" class="mb-4 mt-4" data-aos="fade-right">
        <h1 class="style-3">Our Family</h1>
      </div>

      <div class="row">
        <div class="col">
          <viewer :options="options">
            <img
              :src="Employee.image"
              width="100%"
              class="img-responsive"
              alt=""
            />
          </viewer>
        </div>
      </div>
      <section class="wide-tb-80">
        <div class="row">
          <div
            class="col-12 col-lg-4 col-xl-4 col-sm-12 col-md-4 aboutus text-center"
          >
            We are a leading engineering organization engaged in the
            manufacturing of precision components, where Quality is at the heart
            of every aspect of each component that we make at the benchmark of
            reliability..
          </div>
          <div
            class="border-left col-12 col-lg-4 col-xl-4 col-sm-12 col-md-4 aboutus text-center"
          >
            We are engineers and inventors We design, develop and manufacture
            engineering products using state-of-the-art engineering technology
            and efficient manpower to meet the requirements of our customers
          </div>
          <div
            class="border-left col-12 col-lg-4 col-xl-4 col-sm-12 col-md-4 aboutus text-center"
          >
            Our manufacturing facilities include state of the art equipment,
            engineered layout with process controls and necessary automations
            for quality and productivity.
          </div>
        </div>
      </section>
    </div>

    <!-- <div class="container"> -->
    <section class="mission">
      <div class="missionandvision">
        <div class="row">
          <div class="col-12 col-lg-4" data-aos="fade-right">
            <img src="../assets/img/mission.jpg" class="missimg" alt="" />
            <v-card elevation="24" shaped>
              <v-card-title class="missiontit">Mission</v-card-title>

              <v-card-text>
                <div class="my-4" style="text-align: center">
                  Our passion is to listen to our customers and deliver a
                  product that allows the consumer to feel the pride, respect
                  and trust of everyone and to meet their goals through our
                  innovation, services and solutions
                </div>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-12 col-lg-4" data-aos="fade-up">
            <img src="../assets/img/vision.jpg" class="missimg" alt="" />
            <v-card elevation="24" shaped>
              <v-card-title class="missiontit">Our Vision</v-card-title>

              <v-card-text>
                <div class="my-4" style="text-align: center">
                  To fulfill our mission of streamlined and efficient services,
                  “To give everyone the power to create and share ideas and
                  information, instantly, without barriers.”
                </div>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-12 col-lg-4" data-aos="fade-left">
            <img
              src="../assets/img/quality-policy.jpg"
              class="missimg"
              alt=""
            />
            <v-card elevation="24" shaped
              ><v-card-title class="missiontit">Quality Policy</v-card-title>

              <v-card-text>
                <div class="my-4" style="text-align: center">
                  We are committed to manufacture & supply consistent quality
                  machine components on time to customers, In order to enhance
                  customer satisfaction, by continual improvement, effective
                  implementation and maintenance of quality management system.
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </div>
    </section>

    <!-- </div> -->

    <section>
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-5 col-12 p-0 whychooseimg">
            <img
              src="../assets/img/Whymano.png"
              height="300px"
              class="w-100 img-responsive"
              alt=""
            />
          </div>
          <div class="col-lg-6 col-12">
            <div class="p-5 about-whoose">
              <h1 class="heading-main mb-4" data-aos="fade-in">
                Why Mano Tools?
              </h1>
              <ul class="list-unstyled icons-listing mb-0 w-half">
                <li>
                  <i class="bx bx-right-arrow" style="color: #050674"></i
                  >Professionalism
                </li>
                <li>
                  <i class="bx bx-right-arrow" style="color: #050674"></i>Value
                  for Money
                </li>
                <li>
                  <i class="bx bx-right-arrow" style="color: #050674"></i>Client
                  Satisfaction
                </li>
                <li>
                  <i class="bx bx-right-arrow" style="color: #050674"></i>Timely
                  Delivery
                </li>
                <li>
                  <i class="bx bx-right-arrow" style="color: #050674"></i
                  >Transparency
                </li>
                <li>
                  <i class="bx bx-right-arrow" style="color: #050674"></i>
                  Trusted Team
                </li>
                <li>
                  <i class="bx bx-right-arrow" style="color: #050674"></i
                  >Focused Approach
                </li>
                <li>
                  <i class="bx bx-right-arrow" style="color: #050674"></i
                  >Optimization
                </li>
                <li>
                  <i class="bx bx-right-arrow" style="color: #050674"></i>Cost
                  Effective
                </li>
                <li>
                  <i class="bx bx-right-arrow" style="color: #050674"></i
                  >Quality Standards
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="heading-cerification" data-aos="fade-in">
      <h3>Certifications</h3>
    </div>
    <v-slide-group class="margin mb-4" center-active show-arrows>
      <v-slide-item>
        <v-card
          elevation="21"
          shaped
          color=""
          height="400"
          width="350"
          target="_black"
          class="mx-2"
        >
          <v-img
            src="../assets/img/MSME UDAYAM.png"
            height="100%"
            width="100%"
          />
        </v-card>
      </v-slide-item>
      <v-slide-item>
        <v-card elevation="21" shaped height="400" width="350">
          <v-img
            src="../assets/img/UDYOG ADHAAR.png"
            height="100%"
            width="100%"
          />
        </v-card>
      </v-slide-item>
      <v-slide-item>
        <v-card
          elevation="21"
          shaped
          color=""
          height="400"
          width="350"
          target="_black"
          class="mx-2"
        >
          <v-img src="../assets/img/ISO.png" height="100%" width="100%" />
        </v-card>
      </v-slide-item>
      <v-slide-item>
        <v-card
          elevation="21"
          shaped
          color=""
          height="400"
          width="350"
          target="_black"
          class="mx-2"
        >
          <v-img src="../assets/img/IEC.png" height="100%" width="100%" />
        </v-card>
      </v-slide-item>
      <v-slide-item>
        <v-card
          elevation="21"
          shaped
          color=""
          height="400"
          width="350"
          target="_black"
          class="mx-2"
        >
          <v-img src="../assets/img/GST.png" height="100%" width="100%" />
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
import { db } from "../firebase.js";
export default {
  name: "About",
  components: {},
  data() {
    return {
      Employee: null,
      options: {
        button: true,
        title: false,
        toolbar: false,
        navbar: false,
        tooltip: false,
        movable: false,
        rotatable: false,
        scalable: false,
      },
    };
  },
  created() {
    db.collection("Employee")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          let emp = doc.data();
          emp.id = doc.id;
          this.Employee = emp;
        });
      });
  },
  metaInfo: {
    title: "About Us",
    titleTemplate: "ManoTools - %s",
  },
};
</script>

<style lang="scss" scoped>
#container {
  margin: 0 auto;
  max-width: 520px;
  text-align: center;
}

#container h1 {
  font-weight: normal;
  text-transform: uppercase;
  margin: 0.3125em 0;
  color: #010181;
  background-color: #fff;
  position: relative;
  display: inline-block;
  padding: 0 10px;
}

.style-3 h3 {
  overflow: hidden;
  text-align: center;
  color: #010181;
  text-transform: uppercase;
  // letter-spacing: 3px;
  font-size: 2em;
}

.style-3:before,
.style-3:after {
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  top: -2px;
  vertical-align: middle;
}

.style-3:before {
  background-color: #010181;
  left: -0.5em;
  margin-left: -100%;
  width: 100%;
}

.style-3:after {
  background-color: #010181;
  left: 0.5em;
  margin-right: -100%;
  width: 100%;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.heading {
  text-align: center;
  font-weight: 700;
  color: #ffffff;
}
.aboutus {
  padding: 0 15px;
  text-align: left !important;
  text-align: left;
  font-size: 20px;
  line-height: 200%;
}
.founderimage {
  max-width: 100%;
}
.founder {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 40px 0;
}
.quotes p {
  font-family: "Open Sans", sans-serif;
  text-align: justify;
  font-size: 16px;
  line-height: 40px;
  height: 100%;
  // margin-top: 20%;
}

// MissionandVisionNew

.v-card {
  height: 240px;
}

.missiontit {
  text-align: center;
  font-size: 25px;
  margin: auto;
  color: #010181;
  display: block;
}
.missimg {
  background-color: gray;
  border-radius: 100%;
  border: 3px soild #d4d4d4;
  width: 133px;
  height: 133px;
  object-fit: cover;
  position: relative;
  display: block;
  margin: auto;
  top: 15px;
  z-index: 5;
}
// End
// Founder
.founder .card {
  position: relative;
  width: 300px;
  height: 400px;
  margin: 20px;
  overflow: hidden;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.border-left {
  border-left: 5px solid #010181 !important;
}
.founder .card .content {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  flex-direction: column;
  backdrop-filter: blur(15px);
  box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  transition: bottom 0.5s;
  transition-delay: 0.65s;
}
.founder .card .content {
  bottom: 0;
  transition-delay: 0s;
}
.founder .card .content h3 {
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin: 20px 0 15px;
  line-height: 1.1em;
  transition: 0.5s;
  transition-delay: 0.4s;
  opacity: 0;
  transform: translateY(-20px);
}
.founder .card .content h3 {
  color: #010181;
  opacity: 1;
  transform: translateY(0);
}
.founder .card .content h3 span {
  color: black;
  font-size: 12px;
  font-weight: 300;
  text-transform: initial;
}
.founder .card .content .sci {
  position: relative;
  bottom: 10px;
  display: flex;
}
.founder .card .content .sci li {
  list-style: none;
  margin: 0 10px;
  transform: translateY(40px);
  transition: 0.5s;
  opacity: 0;
  transition-delay: calc(0.2s * var(--i));
}

.founder .card:hover .content .sci li {
  transform: translateY(0);
  opacity: 1;
}
.founder .card .content .sci li a {
  color: white;
  font-size: 24px;
}
.founderbg {
  background: rgba(0, 0, 0, 0.05);
  padding-bottom: 5px !important;
  padding-top: 5px;
}
// end
h2 {
  text-align: center;
}
.jumbotron {
  background: linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.4),
        rgba(5, 6, 116, 0.6)
      )
      fixed,
    url("../assets/img/comp-about.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  border-radius: 0px !important;
}
.mission {
  background: rgba(0, 0, 0, 0.05);
  padding-bottom: 50px;
  padding-top: 30px;
}

.missionandvision {
  margin-right: 2%;
  margin-left: 2%;
}
.wide-tb-80 {
  /* padding-top: 40px; */
  padding-bottom: 30px;
}
.img-responsive {
  margin-top: 5%;
  max-width: 100%;
  margin-bottom: 5%;
  height: auto;
}
.fw-7 {
  font-weight: 700;
}
.fw-6 {
  font-weight: 500;
}

.heading-main {
  color: #010181;
  font-size: 2.25rem;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
}

p {
  font-size: 15px;
  line-height: 40px;
}
.icons-listing li {
  padding: 0 0 20px 35px;
  position: relative;
}

.icons-listing li .bx {
  padding-right: 20px;
}
.icons-listing li {
  flex: 1 1 50%;
}

.p-5 {
  padding: 5px !important;
}
.icons-listing.w-half {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  // display: block;
}
// Mission and Vission end

.heading-cerification {
  text-align: center;
  font-weight: 700;
  color: 010181;
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 30px;
  margin-top: 70px;
  color: #010181;
}

@media (min-width: 992px) {
  .img-responsive {
    height: 500px !important;
  }
}
@media (max-width: 767px) {
  .border-left[data-v-039c5b43] {
    border-left: hidden !important;
  }
}
@media (max-width: 998px) {
  .whychooseimg {
    display: none;
  }
  .icons-listing.w-half {
    margin-left: 10%;
  }
}
@media (max-width: 762px) {
  .img-responsive {
    height: auto !important;
  }
}

@media (max-width: 575px) {
  .icons-listing.w-half li {
    flex: 1 1 100%;
  }
  .aboutus {
    text-align: left;
    column-count: 1;
  }
  .heading-main {
    margin-left: 50px;
    font-size: 1.5rem;
  }
}
</style>
